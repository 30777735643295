import React from 'react';
import styled from 'styled-components';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {products as details} from './product.js'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import './ScannedProduct.css'
import IngridientsTab from "./IngridientsTab.js";

const NutritionalTab = (props) => {
  console.log(props.data);
    return <div style={{display: 'flex', flexDirection: 'column'}}>
         <Nfacts>Nutritonal Facts</Nfacts>
         {details && details.map((row,i) => (
<DetailsBox>
<div style={{textAlign: "center"}}>
<p>{row.NutrionalFacts.Calories}</p>
<p>Calories</p>
</div>
<div style={{textAlign: "center"}}>
<p>{row.NutrionalFacts['Sat Fat (g)']}</p>
<p>Sat fat(g)</p>
</div>
<div style={{textAlign: "center"}}>
<p>{row.NutrionalFacts['Sodium (mg)']}</p>
<p>Sodium(mg)</p>
</div>
<div style={{textAlign: "center"}}>
<p>{row.NutrionalFacts['Sugars (g)']}</p>
<p>Sugar(g)</p>
</div>
</DetailsBox>

         ))}
 
         {props.data && props.data.map((row, index) => (
           <List>
          <ListItem disablePadding>
          <ListItemButton>
          
            <ListItemText sx={{textAlign:'left',width:'50%'}}  primary={row.name}/>
            <ListItemText sx={{textAlign:'center',background:'#F9F9F9',borderRadius:'0px 4px 4px 0px',minWidth:'50%'}} primary={row.quantity_contained}  />
          </ListItemButton>
        </ListItem>
        </List>
        ))}
    

    
       
     
       
       
    
     
        
    </div>;
}


const Nfacts= styled.div`


width: 131px;
height: 24px;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
align-self: center;



color: #494949
`;

const DetailsBox=styled.div`
/* border:1px solid black; */
justify-content:space-evenly;
border-radius:10px;
display:flex;
flex-direction:row;
background-color:#F6F6F6;

div{
p{
    &:nth-child(1) {
    font-family: Poppins;
font-style: normal;
font-weight: 900;
font-size: 20px;
line-height: 21px;

color: #494949;
@media (max-width: 400px) {
  font-size: 18px;
}
    }
  &:nth-child(2){
   
font-family: Poppins;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 21px;

color: #494949;
@media (max-width: 400px) {
  font-size: 12px;
}
  }
}
}

`;


export default NutritionalTab;

