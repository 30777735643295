import React,{useState,useEffect} from "react";
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {products as details} from './product.js'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import './ScannedProduct.css'
import IngridientsTab from "./IngridientsTab.js";
import NutritionalTab from "./NutritionalTab.js";
import AllergensTab from "./AllergensTab.js";
import RecyclingTab from "./RecyclingTab.js";
import Grid from '@mui/material/Grid';
import CookingTab from "./CookingTab.js";
import ProductSuggestTab from "./ProductSuggestTab.js";
import { useHistory,useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    
    color: '#949494',
   
  
     '& .MuiTabs-indicator':{
display: 'none'
     },
     '& .MuiButtonBase-root':{
     
      textTransform: 'capitalize',
      fontSize:'19px',

      "@media (max-width: 900px)": {
        fontSize:'17px',
      }
    
     
  },
     '& .MuiButtonBase-root.Mui-selected':{
         background: '#E8E9FF',
         color: '#4C53DA',
         borderRadius:'12px',
        
     },
     '& .MuiTabs-flexContainer':{
       justifyContent: 'space-between'
     }
    
  },
});

const ScannedProduct = () => {

  const [value, setValue] = useState('Ingredients');
  const [ingridientsData, setingridientsData] = useState('')
  const [nutrionalData, setnutrionalData] = useState('')
  const [allergensData, setallergensData] = useState('')
  const [recyclingData, setrecyclingData] = useState('')
  const [cookingInfo, setcookingInfo] = useState('')
  const [productSuggestData, setproductSuggestData] = useState('')
  const [productData, setproductData] = useState('')
  const [productData1, setproductData1] = useState('')
  const [productData2, setproductData2] = useState('')
  const [batchNo, setbatchNo] = useState('')
  const [host, sethost]= useState('https://stegofy-heroku-001.herokuapp.com')
const {mfrId}=useParams();
    const history=useHistory();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // useEffect(() => {
    //   axios
    //   .get(`http://localhost:5000/productInfo/1`)
    //   .then((response) => {
    
    //     console.log(response.data)
    //     //  setexpiryDate(response.data[0].expiry_date)
    //     //  setproductId(response.data[0].product_id);
    //     //  setproductName(response.data[0].product_name)
    //   }).catch( error => console.log(error));
    // },[])

    useEffect(() => {
      axios
      .get(`${host}/productIngredientInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setingridientsData(response.data)
        //  setexpiryDate(response.data[0].expiry_date)
        //  setproductId(response.data[0].product_id);
        //  setproductName(response.data[0].product_name)
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/productNutritionalInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setnutrionalData(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/productAllergenInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setallergensData(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/recycleInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setrecyclingData(response.data)
       
        
      }).catch( error => console.log(error));
    },[])
    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/productCookingInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setcookingInfo(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/productSuggestionsInfo/1`)
      .then((response) => {
        
        console.log(response.data)
        setproductSuggestData(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/productInfo/parent/1`)
      .then((response) => {
        
        console.log(response.data)
        setbatchNo(response.data[0].batch_number)
        setproductData(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/getProductInfoQR/65`)
      .then((response) => {
        
        console.log(response.data)
        setproductData1(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    useEffect(() => {
      axios
      .get(`https://stegofy-heroku-001.herokuapp.com/profile/58`)
      .then((response) => {
        
        console.log(response.data)
        setproductData2(response.data)
       
        
      }).catch( error => console.log(error));
    },[])

    const backArrow=()=>{
    //   history.push(`/ProductVerified/65`)
    //  \
      history.goBack();
    }
    const classes = useStyles();
  return (
   

            

  <div className="mainContainer" style={{ display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
  {productData && productData.map((row,index)=>(

<div className="ImgProduct" style={{width: '30%',display: 'flex',flexDirection: 'column'}}>
<div style={{display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
<ArrowBackIosIcon onClick={()=>backArrow()} style={{width: '24px',height: '24px',alignSelf:'center'}}/>
<ScanProduct>Scanned Product</ScanProduct>
</div>
<Image>
 <img src='/images/image1.png'/>
</Image>


<div className="imageOverlapBox" >

<div style={{borderRadius:'25px'}}>
    <div>
    <p className="productName" style={{margin:'0 5px'}}> {row.name}</p>
    <p className="productPrice" style={{margin:'0 5px',fontSize:'19px'}}>₹ 30</p>
    </div>
    
    <p className="productDescription" style={{color:'#666666'}}>{row.description}</p>
    </div>
    <div style={{background: '#F7F7F9',borderBottomLeftRadius:'25px',borderBottomRightRadius:'25px',display: 'flex',flexDirection: 'row',justifyContent: 'space-evenly'}}>

     <div style={{margin: '15px',alignSelf: 'center'}}><img src="/images/Send.svg"/></div>
     <div style={{margin: '15px',textAlign: 'center',display: 'flex',flexDirection: 'row',justifyContent: 'center'}}><img src="/images/Star.png" style={{fontSize:'3rem',alignSelf:'center'}}/>
     <p style={{alignSelf:'center'}}>4.0</p></div>
     <div style={{margin: '15px',alignSelf:'center'}}><img src="/images/Bookmark.png"/></div>
    </div>
     </div> 
             
  </div>

  ))}
     




        <div class="detailsContainer" style={{width: '33%',display: 'flex',flexDirection: 'column'}}>
        <Container>
            {productData1 && productData1.map((row,index)=>(

<Box1>
<OriginBox>
{/* <DateRangeIcon sx={{fontSize:'7em',color:"#4C53DA"}}/> */}
<img style={{alignSelf:'center'}} src="/images/calendarIcon.svg"/>
<p style={{fontSize:'23px',fontFamily:'Poppins',color:"#4C53DA",alignSelf:'center'}}>Date/Time</p>
</OriginBox>
<DetailBox>
<div className="consumeText" style={{display:'flex',flexDirection:'row'}}>
<p className="consumeText" style={{fontSize:'18px',fontFamily:'Poppins',color:"#4C53DA"}}>Consume Within:</p><p style={{fontSize:'17px',fontFamily:'Poppins',alignSelf:'center'}}>{row.consume_within}</p>
</div>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'15px'}}>
<div className="manfact" style={{width: '82px',margin:'3px',alignSelf:'center'}}>
<p style={{fontSize:'13px'}}>Mfd.Date:</p><p style={{fontSize:'15px'}}>{row.generated_date}</p>
</div>
<div className="expDate"  style={{maxWidth: '112px',alignSelf:'center'}}>
<p   style={{fontSize:'13px'}}>Expiry Date:</p><p style={{fontSize:'15px'}}>{row.expiry_date}</p>
</div>
</div>
</DetailBox>
</Box1>


            ))}

            {productData2 && productData2.map((row,index) => (

<Box2>
<DateBox>
    <img style={{alignSelf: 'center'}} src="/images/originIcon.svg"/>
    <p style={{fontSize:'23px',fontFamily:'Poppins',color:"#4C53DA"}}>Origin</p>
    </DateBox>
    <DetailBox>
      <div>
      <p className="batchNo" style={{fontSize:'16px',weight:'500',fontFamily:'Poppins',lineHeight:"15px"}}>BatchNo: {batchNo}</p>
        <p className="batchName" style={{fontSize:'19px',weight:'500',fontFamily:'Poppins',lineHeight:"21px",color:'#4C53DA'}}>{row.brandName}</p>
      </div>
       
        <p className="batchAdd" style={{fontSize:'16px',weight:'500',fontFamily:'Poppins',lineHeight:"23px"}}>{row.address}</p>

    </DetailBox>
</Box2>


            ))}
           
           

        </Container>
      
        </div>



        <div className="tabsContainer" style={{width: '36%',display: 'flex',flexDirection: 'column'}}>
      {details && details.map((row, index) => (
        <Box sx={{ width: '100%',marginTop: '15px',display: 'flex',flexDirection: 'column'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="#4C53DA"
          // indicatorColor="secondary"
          // variant="fullWidth"
          variant="scrollable"
          scrollButtons="auto"
          className={classes.root}
          sx={{margin:'8px'}}
        >
          <Tab value="Ingredients" label="Ingredients" />
          <Tab value="Nutritonal" label="Nutritonal" />
          <Tab value="Allergens" label="Allergens" />
          <Tab value="Recycling" label="Recycling" />
          <Tab value="Cooking" label="Cooking" />
          <Tab value="Productsuggestion" label="Product Suggestions" />
        
        </Tabs>
        <div className="tabsInfo" style={{paddingInline:'50px'}}>
        {value=="Ingredients" && <IngridientsTab data={ingridientsData}/>}
        {value=="Nutritonal" && <NutritionalTab data={nutrionalData}/>}
        {value=="Allergens" && <AllergensTab data={allergensData}/>}
        {value=="Recycling" && <RecyclingTab data={recyclingData}/>}
        {value=="Cooking" && <CookingTab data={cookingInfo}/>}
        {value=="Productsuggestion" && <ProductSuggestTab data={productSuggestData}/>}
        </div>

   
       
     
        
      </Box>
        ))}
        
        </div>
        {/* <div style={{width: '25%'}}>

        <div style={{display: 'flex', flexDirection: 'column'}}>
            
            <hr></hr>
        <p style={{fontFamily:'poppins',weight:'500',fontSize:'16px',lineHeight:'24px',alignSelf:'center'}}>Vitamins & Minerals</p>
       
            {details && details.map((row, index)=>(
               <Barlist >
                    <div style={{background: '#855CF8',opacity:'10'}}>
                <p >Vitamin A ({row.VitaminsList['Vitamin A']})</p>
            </div>
            <div style={{background: '#855CF8',width:'80%',opacity:'0.8'}}>
                <p >Vitamin d ({row.VitaminsList['Vitamin D']})</p>
            </div>
            <div  style={{background: '#859CF8',opacity:'0.6'}} >
                <p>Vitamin E ({row.VitaminsList['Vitamin E']})</p>
            </div>
            <div  style={{background: '#859CF8',width:'60%',opacity:'0.5'}}>
                <p>Vitamin k ({row.VitaminsList['Vitamin K']})</p>
            </div>
            <div  style={{background: '#859CF8',width:'70%',opacity:'0.4'}}>
                <p>Vitamin C ({row.VitaminsList['Vitamin C']})</p>
            </div>
            <div  style={{background: '#859CF8',width:'50%',opacity:'0.3'}}>
                <p>Thiamin ({row.VitaminsList['Thiamin']})</p>
            </div>
            <div  style={{background: '#F9F9F9',width:'70%'}}>
                <p style={{color:'black'}}>Vitamin B6({row.VitaminsList['Vitamin B6']})</p>
            </div>
            <div  style={{background: '#F9F9F9',width:'30%',opacity:'0.9'}}>
                <p style={{color:'black'}}>Folic Acid ({row.VitaminsList['Folic Acid']})</p>
            </div>

        </Barlist>

            ))}
           
        </div>
       
        </div> */}
      
      </div>
    

           
    
     
     
   
  );
};



const Container = styled.div`
margin-top:50px;
font-family: 'Poppins', sans-serif;
width: 100%;
display:flex;
flex-direction: column;
justify-content: space-evenly;

`;

const Box1 = styled.div`
min-width: 500px;
min-height: 180px;
background: #F9F9F9;
border-radius: 12px;
margin: 10px;
display:flex;
flex-direction: row;
justify-content: space-evenly;
align-self: center;
padding: 2px;
p{
width: 165px;
height: 18px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
}
@media (max-width: 500px ) {
  max-width: 415px!important;
  min-width: 0px!important;
  
  }
@media (max-width: 900px and max-width:547px) {
    min-width: 500px;
  }
  @media (max-width: 400px  ) {
  /* max-width: 335px!important; */
width: 335px!important;
  
  }
  

`;

const Box2 = styled.div`

min-width: 500px;
min-height: 180px;
background: #F9F9F9;
border-radius: 12px;
margin: 10px;
padding: 2px;
display: flex;
flex-direction: row;
align-self: center;

@media (max-width: 500px  ) {
  /* max-width: 335px!important; */
  min-width: 427px!important;
  
  }
  @media (max-width: 400px  ) {
  /* max-width: 335px!important; */
  min-width: 335px!important;
  
  }
@media (max-width: 900px and max-width:547px) {
    min-width: 500px;
  }
`;

const DateBox=styled.div`

display:flex;
flex-direction: column;
justify-content: center;
width: 50%;
    float: left;
    text-align: center;

`;

const OriginBox=styled.div`

display:flex;
flex-direction: column;
    width: 50%;
    float: left;
    justify-content: center;
    text-align: center;

`;

const DetailBox=styled.div`

    width: 50%;
    float: left;
    display: flex;
    flex-direction: column;
    /* @media (max-width: 525px) {
    div{
&:nth-child(1){
  p{
    &:nth-child(1){
      font-size: 15px!important;
    }
    &:nth-child(2){
      font-size: 14px!important;
    }
  }
}
&:nth-child(2){
  div{
    &:nth-child(1){
      p{
        &:nth-child(1){
          font-size: 11px!important;
        }
      }
    }
  }
}
}
} */
  
`;






const ImgContainer = styled.div`

width: 25%vw;
max-height: 616.48px;



border-radius: 30px;
margin:5px;

div{
  text-align: center;
}


`;

const Image = styled.div`
/* background: #C4C4C4; */
width: 100%;
text-align: center;
img{
  width: 369px;
height: 140%;

border-bottom-left-radius: 25px;
border-bottom-right-radius: 25px;
 
}

`;

const ScanProduct = styled.p`
 
width: 139px;
height: 24px;


font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;

color: #494949;
`;

const DetailsBox=styled.div`
/* border:1px solid black; */
justify-content:space-evenly;
border-radius:10px;
display:flex;
flex-direction:row;
/* background-color:#F7F7F9; */
p{
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
color: #666666;
}
div{
  p{
   
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;

color: #494949;
  }
}

`;


const Nfacts= styled.div`


width: 131px;
height: 24px;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
align-self: center;



color: #494949
`;



const Barlist= styled.div`
width: 100%;
align-self: center;
div{
background: #855CF8; 

border-radius: 0px 4px 4px 0px;
font-family: Poppins;


p{
font-family: Poppins;
border-radius: 0px 4px 4px 0px;
padding:7px;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 18px;

text-align: right;
letter-spacing: 0.451506px;
text-transform: capitalize;

/* Light Color */

color: #FFFFFF;
}
}

`;


export default ScannedProduct;
