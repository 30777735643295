import React,{useState,useEffect} from "react";
import { useHistory,useParams } from "react-router-dom";
import Style from "./productView.module.css";
import styled from 'styled-components';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {products as details} from './product.js'
import './ScannedProduct.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';


const ProductVerified = () => {
  
  const [qrId, setqrId] = useState('')
  const [productId, setproductId] = useState('')
  const [expiryDate, setexpiryDate] = useState('')
  const [productName, setproductName] = useState('')
  const [mfrId, setmfrId] = useState('')
  const [productData, setproductData] = useState('')
  const history = useHistory();
let {id}=useParams();

// useEffect(() => {
//   (async () => {
//     const id = localStorage.getItem("current_product_id");
//     await axios
//       .get(`/productVitaminsInfo/${id}`)
//       .then((response) => {
//         setexpiryDate(response.data[0].expiry_date)
//      setproductId(response.data[0].product_id);
//      setproductName(response.data[0].product_name)
//      setmfrId(response.data[0].mfr_id)
 
//     console.log(response.data)
//       }).catch( error => console.log(error));
//   })();
// }, []);


useEffect(() => {
  (async () => {
   await axios
  .get(`https://stegofy-heroku-001.herokuapp.com/getProductInfoQR/${id}`)
  .then((response) => {
    
     setexpiryDate(response.data[0].expiry_date)
     setproductId(response.data[0].product_id);
     setproductName(response.data[0].product_name)
     setmfrId(response.data[0].mfr_id)
 
    console.log(response.data)
    
  }).catch( error => console.log(error));

  })();
  
},[])

useEffect(()  => {
   axios
  .get("https://stegofy-heroku-001.herokuapp.com/productInfo/58")
  .then((response) => {

    console.log(response.data)
    //  setexpiryDate(response.data[0].expiry_date)
    //  setproductId(response.data[0].product_id);
    //  setproductName(response.data[0].product_name)
  }).catch( error => console.log(error));
},[])

  const viewMore = () =>{
       history.push(`/scannedProduct/34`) 
  };
     return (
      
    <div>
       <div  style={{width: '100%',display: 'flex',flexDirection: 'column'}}>
      <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
  <ArrowBackIosIcon style={{width: '24px',height: '24px',alignSelf:'center'}}/>
<ScanProduct>Product Verified</ScanProduct>
</div>
   <Image>
       <img src='/images/image1.png'/>
   </Image>
  
   
     <div className="imageOverlapBoxVerified" >

    
    <div>
          <div><p>Product:</p><p>{productName}</p>  </div>
          <div ><p>Batch:</p><p></p> </div>
          <div ><p>Origin: </p> <p>India</p></div>
          <div><p>State:</p><p>Mumbai, MH,</p>  </div>
          <div><p>Expiry:</p> <p>{expiryDate}</p></div>
         
          </div>
           </div> 
       
          <div style={{display:"flex", flexDirection:"row",marginTop:'304px',alignSelf:'center'}}>
          <div style={{display:"flex", flexDirection:"column"}}>
         <button className={Style.button}   >Trace</button>
        <button className={Style.button}   onClick={viewMore} >View More</button>
          </div>
          <div style={{display:"flex", flexDirection:"column"}}>
        <button className={Style.button}   >Claim</button>
          <button className={Style.button}   >Report</button>
          </div>
      </div>
        </div>
      {/* <div>
      <p className={Style.heading}>Product Verified</p>
      <p className={Style.para}>
        If you have any concerns or queries in relation to the product you have
        in your possesion, please contact us by choosing Report button below.
      </p>
      <div className={Style.image}>
        <img src="/images/image1.png" alt="product imag" />
      </div>
      <div className={Style.card}>        
          <div style={{paddingBottom:"10px"}} ><span style={{color:"#797979", marginRight:"50px", paddingLeft:"4px"}} >Product</span> Finery Lemon Myth Balck Tea </div>
          <div style={{paddingBottom:"10px"}} ><span style={{color:"#797979", marginRight:"50px", paddingLeft:"4px" }} >Batch</span> 3132ha34</div>
          <div style={{paddingBottom:"10px"}}><span style={{color:"#797979", marginRight:"50px", paddingLeft:"4px" }} >Origin </span> India</div>
          <div style={{paddingBottom:"10px"}}><span style={{color:"#797979", marginRight:"50px", paddingLeft:"4px" }} >State</span> Mumbai, MH, </div>
          <div style={{paddingBottom:"10px"}}><span style={{color:"#797979", marginRight:"50px", paddingLeft:"4px" }} >Expiry</span> 20/08/2021</div>
       </div>
      <div style={{display:"flex", flexDirection:"row", position:"absolute", top:"640px"}}>
          <div style={{display:"flex", flexDirection:"column"}}>
         <button className={Style.button}   >Trace</button>
        <button className={Style.button}   onClick={viewMore} >View More</button>
          </div>
          <div style={{display:"flex", flexDirection:"column"}}>
        <button className={Style.button}   >Claim</button>
          <button className={Style.button}   >Report</button>
          </div>
      </div>
      </div> */}

     
    </div>
     );
};

const Image = styled.div`
/* background: #C4C4C4; */
width: 100%;
text-align: center;
img{
  width: 355px;
height: 241px;

border-bottom-left-radius: 25px;
border-bottom-right-radius: 25px;
 
}

`;

const ScanProduct = styled.p`
 
width: 139px;
height: 24px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #494949;
`;


export default ProductVerified;
