import React from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


const CookingTab = (props) => {
    
    return <div>
         <FormControl sx={{ m: 1, minWidth: 120,borderRadius:'25px' }}>
         <InputLabel id="demo-simple-select-helper-label">Recipe</InputLabel>
        <Select
        //   value={age}
        //   onChange={handleChange}
          label="Recipe"
        //   displayEmpty
        //   inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={1}>Ten</MenuItem>
          <MenuItem value={2}>Twenty</MenuItem>
          <MenuItem value={3}>Thirty</MenuItem>
        </Select>
      </FormControl>
      {props.data && props.data.map((row, i) =>(

<div>
<div>
    <p>Step {row.step_no}</p>
    <p>{row.cooking_step}.</p>
    <ImageBlock>
    <img src="/images/step1img.svg"/>
    <img src="/images/step1newimg.svg"/>
    <img src="/images/step1newimg.svg"/> 
    </ImageBlock>
</div>

</div>

      ))}
    
    </div>;
}

const ImageBlock =styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
img{
    border-radius:12px;
}
`;


export default CookingTab;