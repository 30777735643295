import React from 'react';
import styled from 'styled-components';

const AllergensTab = (props) => {
    return <div style={{display:'flex',flexDirection: 'column'}}>
        <h3>May Contain</h3>
       
             <div style={{display:'flex',flexDirection: 'row',justifyContent: 'space-evenly'}}>
             {props.data && props.data.map((row,index)=>(
             <div style={{display:'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
             <img style={{alignSelf: 'center'}} src="/images/typeDPIcon.svg"/>
             <p style={{alignSelf: 'center'}}>Type : {row.type_code}</p>
             </div>
                 ))}
         </div>

    
       
        <div style={{display:'flex',flexDirection: 'row',justifyContent: 'space-evenly',marginTop:'15px'}} >
            <Item>
            <img src="/images/eggIcon.svg"/>
            </Item>
            <Item>
            <img src="/images/mikIcon.svg"/>
            </Item>
           <Item>
            <img src="/images/peanutIcon.svg"/>
            </Item>
        </div>
    </div>;
}


const Item =styled.div`

padding: 20px 30px 20px 30px;
background-color:#F9F9F9;
border-radius: 12px;
@media (max-width:500px){
margin-inline: 10px;
}
&:nth-child(1){
    border: 2px solid #73CDFF;
}
&:nth-child(2){
    border: 2px solid #FFECA1;
}
&:nth-child(3){
    border: 2px solid #FFECA1;
}


`;


export default AllergensTab;