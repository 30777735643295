export const products = [
    {"name":"Finery Lemon Myth Balck Tea (250 ml)",
    "rating":"4",
    "consume":"3 months",
    "description":"Product description goes here. Amet minim mollit non deserunt ullamco est sit aliqua dolor do.",
    "mfgdate":"20/08/2021",
    "expdate":"16/05/2021",
    "batchname":"Britania Incorporation",
    "batchno":"3132ha34",
    "address":"4/153, tower-1 gandhi nagar,\nMumbai, Maharashtra, 07",
    "NutrionalFacts":{"Calories":"40",
    "Sat Fat (g)":"0",
    "Sodium (mg)":"90",
    "Sugars (g)":"22",
    "Energy":"9g",
    "Fat of which":"9g",
    "Saturates":"8g",
    "Mono-saturates":"7g",
    "Polyunsaturates":"5g",
    "Sugar":"4g",
    "Starch":"5g",
    "Fat":"6g"},
    "VitaminsList":{"Vitamin A":"9g",
    "Vitamin D ":"8g",
    "Vitamin E":"9g",
    "Vitamin K":"6g",
    "Vitamin C":"7g",
    "Thiamin":"5g",
    "Vitamin B6":"7g",
    "Folic Acid":"4g"}}
   
];