import React,{useRef,useCallback,useState,useEffect} from 'react';
import styled from 'styled-components';
import Webcam from "react-webcam";
import "./webcam.css"
import QrReader from 'react-qr-reader'
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
  root: {
    
    color: '#949494',
   
  
     '& .MuiTabs-indicator':{
display: 'none'
     },
     '& .MuiButtonBase-root':{
     
      textTransform: 'capitalize',
      fontSize:'14px'
    
     
  },
     '& .MuiButtonBase-root.Mui-selected':{
         background: '#E8E9FF',
         color: '#4C53DA',
         borderRadius:'12px',
        
     }
  },
});


const videoConstraints = {
    width: 1280,
    height: 523,
     facingMode: "Facing-Out"
  };

const CaptureView = () => {

    const webcamRef = useRef(null);
    const [image, setimage] = useState(null)
    const [result, setresult] = useState('')
    const [host, sethost] = useState('https://stegofy-heroku-001.herokuapp.com')
    const history = useHistory ();

        const capture = useCallback(
          () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setimage(imageSrc);
          },
          [webcamRef]
        );

        
        const handleScan=(data)=>{
        if(data!=null){
          // http://localhost:3000/home
          const newId = data.split("QRCode");
            setresult(newId[0]);
           console.log(newId[0]);
            const id=parseInt(newId[0]);
           axios
           .get(`${host}/getProductInfoQR/${id}`)
           .then((response) => {
             console.log(response);
             history.push(`/ProductVerified/${id}`);
             
           }).catch( error => console.log(error));
        
  
        }
      
      }
       const handleError = err => {
     
          console.error(err)
        }

        const classes = useStyles();
    return <div className="webcamPage">
       <QrReader
          delay={300}
           onError={handleError}
           onScan={handleScan}
           className="webcamCapture"
           height={videoConstraints.height}
           width={videoConstraints.width}
          style={{ width: '50%',height: '350px' }}
        />
      <img src="./images/leftArrow.svg" className="leftArrow"/>
      
      <div className="webcam_heading">Scan the QR</div>
      <div className="webcam_text">
      Place the non-cloneable QR code in the frame to verify the authenticity of product and claim it yours.
      </div>
      <img src="/images/thunderIcon.svg" className="thunder_Icon"/>
      <img src="/images/tickButton.svg" className="Capture_button" onClick={capture}/>
      {/* <button >Capture photo</button>
<img src={image}/> */}
    </div>;
}



export default CaptureView;