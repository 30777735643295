import React from 'react';
import styled from 'styled-components';

const RecyclingTab = (props) => {
    return <div style={{marginTop:'10px'}}>
        {props.data && props.data.map((row,index) =>(
             <div>
             <div style={{display: 'flex', flexDirection: 'row'}}>
             <img src="/images/recyclingIcon.svg"/>
             <span style={{alignSelf: 'center',marginLeft:'10px',color: ' #494949'}}>{row.recycling_type}</span>
             </div>
             <p style={{color: '#666666'}}>{row.how_to_recycle}</p>
             </div>

        ))}
       
    </div>;
}


export default RecyclingTab;