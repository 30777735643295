import "./App.css";
import { Switch, Route } from "react-router-dom";
import ProductVerified from "./component/productVerified";
import ScannedProduct from "./component/ScannedProduct";
import Scan from "./component/scan";
import Home from "./component/Home";
import CaptureView from "./component/CaptureView";
import { TrackChanges } from "@mui/icons-material";
import TraceSection from "./component/TraceSection";

function App() {
  return (
    <Switch className="App">
      <Route path="/scan">
        <Scan/>
      </Route>
      <Route path="/home">
        <Home/>
      </Route>
      <Route path="/scannedProduct/:productId">
        <ScannedProduct/>
      </Route>
      <Route path="/captureView">
        <CaptureView/>
      </Route>
      <Route path="/ProductVerified/:id">
        <ProductVerified/>
      </Route>
      <Route path="/traceProduct">
        <TraceSection/>
      </Route>
    </Switch>
  );
}

export default App;
