import React from 'react';
import styled from 'styled-components';




const IngridientsTab = (props) => {
    

    return <div style={{display: 'flex', flexDirection:'column'}}>
        <h3>Containing Ingridients</h3>
        {props.data && props.data.map((row,index)=>(

<div style={{display: 'flex', flexDirection:'column'}}>
<Tabs>
    <p style={{minWidth:row.quantity_contained + '%',background: '#DCDEDF',borderRadius: '0px 4px 4px 0px',height: '32px'}}>{row.name}</p>
    <p>{row.quantity_contained}</p>
</Tabs>

</div>

        ))}
       
    </div>;
}

const Tabs= styled.div`

display:flex;
flex-direction:row;
height: 36px;

p{
    align-self:center;
    padding:2px 0px 2px 6px;
}
@media (max-width: 450px){
    p{
        &:nth-child(1){
            font-size:12.5px;
        }
    }
}
`;

export default IngridientsTab;

