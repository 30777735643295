import React from 'react';
import styled from 'styled-components';

const ProductSuggestTab = (props) => {
    return <div >
        <h3>Serve With</h3>
        {props.data && props.data.map((row,index) =>(

<ItemContainer>
<Item>
<img src="/images/burger.svg"/>
<span>{row.serve_with}</span>
</Item>

</ItemContainer>

        ))}
      
    </div>;
}

const ItemContainer= styled.div`

display:flex;
flex-direction:row;
justify-content:space-around;
flex-wrap:wrap;
`;

const Item= styled.div`

display:flex;
flex-direction:row;
justify-content:space-around;
background-color:#F9F9F9;
border-radius: 12px;
padding: 10px;
margin:10px;
min-width:38%;
@media (max-width:400px){
width: 47%;
}
img{
  align-self: center;
}
span{
  align-self: center;
}

`;

export default ProductSuggestTab;