import React,{useState,useEffect} from "react";
import { useHistory,useParams } from "react-router-dom";
import Style from "./productView.module.css";
import styled from 'styled-components';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {products as details} from './product.js'
import './ScannedProduct.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';



const TraceSection = () => {
  
  const [qrId, setqrId] = useState('')
  const [productId, setproductId] = useState('')
  const [expiryDate, setexpiryDate] = useState('')
  const [productName, setproductName] = useState('')
  const [mfrId, setmfrId] = useState('')
  const [productData, setproductData] = useState('')
  const history = useHistory();
// let {id}=useParams();

// useEffect(() => {
//   axios
//   .get(`http://localhost:5000/getProductInfoQR/${id}`)
//   .then((response) => {
    
//      setexpiryDate(response.data[0].expiry_date)
//      setproductId(response.data[0].product_id);
//      setproductName(response.data[0].product_name)
//      setmfrId(response.data[0].mfr_id)
 
//     console.log(response.data)
//   }).catch( error => console.log(error));
// },[])

  const viewMore = () =>{
       history.push(`/scannedProduct/${mfrId}`) 
  };
     return (
      
    <div>
       <div  style={{display: 'flex',flexDirection: 'column',justifyContent: 'center'}}>
      <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-around',alignSelf:'center',minWidth:'545px'}}>
  <ArrowBackIosIcon style={{width: '24px',height: '24px',alignSelf:'center'}}/>
<ScanProduct>Track Order</ScanProduct>
<img style={{alignSelf:'center'}} src="/images/traceIconMenu.svg"/>
</div>
<SectionMain>
<Section2>
<Image>
       <img src='/images/image1.png'/>
   </Image>
   <ProductName>
   Finery Lemon M. Balck Tea
( 250 ml )
   </ProductName>
  

</Section2>
<Divider ></Divider>
<Skeleton>
    <Part1>
        <Part11>
            <Time>3:51 am</Time>
            <Time>3:51 am</Time>
            <Time>3:51 am</Time>
            <Time>3:51 am</Time>
            <Time>3:51 am</Time>

        
        
        </Part11>
<Part12>
<IconCircle>
    <img src='/images/traceStep1.svg'/>
</IconCircle>
<Line></Line>
<IconCircle>
    <img src='/images/traceStep1.svg'/>
</IconCircle>
<Line></Line>
<IconCircle>
    <img src='/images/traceStep1.svg'/>
</IconCircle>
<Line></Line>
<IconCircle>
    <img src='/images/traceStep1.svg'/>
</IconCircle>
<Line></Line>
<IconCircle>
    <img src='/images/traceStep1.svg'/>
</IconCircle>
</Part12>
</Part1>
<Part2>
<Data>
Monday, March 20 2021, Chennai TN
From <span>Manufacturer</span>
</Data>

<Data>
Monday, March 20 2021, Chennai TN
From <span>Manufacturer</span>
</Data>
<Data>
Monday, March 20 2021, Chennai TN
From <span>Manufacturer</span>
</Data>
<Data>
Monday, March 20 2021, Chennai TN
From <span>Manufacturer</span>
</Data>
<Data>
Monday, March 20 2021, Chennai TN
From <span>Manufacturer</span>
</Data>

</Part2>
</Skeleton>

</SectionMain>
   
  
   
     </div>
          
     

     
    </div>
     );
};

const Part1=styled.div`
width: 50%;
display:flex;
flex-direction:row;
justify-content: space-evenly;

`;

const Data=styled.p`
font-size:15px;
span{
    color: #5860F3;    
}
`;

const Part11=styled.div`
width: 25%;
display:flex;
flex-direction:column;
justify-content:center;
justify-content: space-around;

`;

const Part12=styled.div`
width: 56%;
display:flex;
flex-direction:column;
justify-content:center;

`;


const Part2=styled.div`
width: 50%;
display:flex;
flex-direction:column;
justify-content:space-between;

`;
const Image = styled.div`
/* background: #C4C4C4; */
align-self: center;
text-align: center;
img{
  width: 55px;
height: 55px;

border-radius:50%
 
}

`;

const IconCircle= styled.div`
background: #5860F3;

align-self: center;
text-align: center;
border-radius:50%;
padding:10px;
align-self: center;
z-index:5;
img{
    
width: 62px;
height: 52px;
object-fit:fill;

}
`;

const Skeleton= styled.div`
width:100%;
display:flex;
flex-direction:row;
margin-top:20px;
justify-content: center;
`;

const ProductName=styled.div`
color: #5B5B7E;
font-weight: 500;
font-size: 15px;
align-self: center;
`;

const ScanProduct = styled.p`
 
width: 139px;
height: 24px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
align-self: center;
font-size: 16px;
line-height: 24px;
color: #494949;
`;

const Time= styled.p`
text-align: center;
align-self: center;
font-size: 15px;
color: #494949;
`;
const Line =styled.div`
width: 50px;
height: 22px;
background: #5860F3;
transform: rotate(90deg);
text-align: center;
align-self: center;

z-index:2;
`;


const Section2 = styled.div`
/* background-color: #E5E5E5; */
display:flex;
flex-direction:row;
justifyContent: center;
align-self: center;
`;

const SectionMain = styled.div`
background-color: #E5E5E5;
display:flex;
flex-direction:column;
justifyContent: center;
align-self: center;
border-radius: 30px;
`;

const Divider = styled.div`
height: 0px;
width: 327px;

border-radius: 0px;
border: 1px solid #E8E8E8;
align-self:center;


`;
export default TraceSection;
