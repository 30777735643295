import React from 'react';
import "./Home.css"
import styled from 'styled-components';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const Home = () => {
    return <div style={{height: '100%'}}>
        
        <div className="navbar">
            <div className="navChild">
            <img src="/images/logo.svg"/>
            <h1 style={{color: '#575FEE'}}>Stegofy</h1>
            </div>
            <div className="navChild">
            <img style={{height:'43px'}} src="/images/notificationsIcon.svg"/>
            <img src="/images/profile.svg"/>
            </div>
        </div>
        <div className="nameDetails">
            Hello,<b> Haffez</b>
        </div>
        <div className="midDetails">
         <p>Deals & More</p>
         <p>View More</p>
        </div>
        <div className="midPhoto">
            <img src="/images/imagePoster1.png"/>
            <img src="/images/imagePoster1.png"/>
        </div>

        <div className="itemSection">
            <div><img src="/images/productsIcon.svg"/><p>Products</p></div>
            <div><img src="/images/reviewsIcon.svg"/><p>Reviews</p></div>
            <div><img src="/images/rewardsIcon.svg"/><p>Rewards</p></div>
            <div><img src="/images/expirationIcon.svg"/><p>Expiration</p></div>
            <div><img src="/images/warrantyIcon.svg"/><p>Warranty</p></div>
        </div>
        
        <footer>
            <div><img src="/images/homeIcon.svg"/><p>Home</p></div>
            <div><img src="/images/exploreIcon.svg"/><p>Explore</p></div>
            <div><img src="/images/midFotterIcon.svg"/></div>
            <div><img src="/images/historyIcon.svg"/><p>History</p></div>
            <div><img src="/images/helpIcon.svg"/><p>Help</p></div>
        </footer>
    </div>;
}



export default Home;